import { render } from "./project-page.vue?vue&type=template&id=15abd01c&scoped=true"
const script = {}

import "./project-page.vue?vue&type=style&index=0&id=15abd01c&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-15abd01c"
/* hot reload */
if (module.hot) {
  script.__hmrId = "15abd01c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('15abd01c', script)) {
    api.reload('15abd01c', script)
  }
  
  module.hot.accept("./project-page.vue?vue&type=template&id=15abd01c&scoped=true", () => {
    api.rerender('15abd01c', render)
  })

}

script.__file = "html/vue/project-page.vue"

export default script