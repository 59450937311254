
import { ComponentPublicInstance, computed, defineComponent, getCurrentInstance, onMounted, onUnmounted, Ref, ref, toRef, watch } from 'vue';
declare var __UPDATE_TIME__ : number;

interface ScrollObserverData {
    position: { top: number, left: number },
    direction: 'up'|'down'|'left'|'right',
    directionChanged: boolean,
    delta: { top: number, left: number },
    inflectionPoint: { top: number, left: number }
}

function pageChangeSetup() : {currentPage:Ref<string>,currentSection:Ref<string>} {
    const currentHash = () : {page:string,section?:string} => {
        let hash = window.location.hash;
        if(hash && hash.substring(0, 1)=='#') hash = hash.substring(1);
        if(!hash) return {page:'main'};
        const dashPos = hash.indexOf('-');
        if(dashPos >= 0) {
            return {
                page: hash.substring(0, dashPos),
                section: hash,
            };
        } else {
            return {
                page: hash,
                section: hash,
            };
        }
    };

    const hash = currentHash();
    const currentPage = ref(hash.page);
    const currentSection = ref(hash.section || '');

    const onPopState = (event:PopStateEvent) : void => {
        const hash = currentHash();
        currentPage.value = hash.page;
        currentSection.value = hash.section || '';
    };
    watch(currentPage, (newValue) => {
        if(newValue != currentHash().page) {
            window.location.hash = '#'+newValue;
        }
    });
    watch(currentSection, (newValue) => {
        if(!newValue) {
            window.location.hash = '#'+currentPage.value;
        }
        else if(newValue != currentHash().section) {
            window.location.hash = '#'+newValue;
        }
    });

    let isWatched = false;
    onMounted(() => {
        if(!isWatched) {
            window.addEventListener('popstate', onPopState);
            isWatched = true;
        }
    });
    onUnmounted(() => {
        if(isWatched) {
            window.removeEventListener('popstate', onPopState);
            isWatched = false;
        }
    });

    return { currentPage, currentSection };
}

export default defineComponent({
    props: {
        useQuasar: { type:Function, required:true },
    },
    setup (props) {
        const quasar = props.useQuasar();
        const updateTime = new Date(__UPDATE_TIME__);
        const pageVals = pageChangeSetup();
        
        return {
            leftDrawerOpen: ref(false),
            currentPage: pageVals.currentPage,
            currentTab: pageVals.currentSection,
            isDark: computed<boolean>(() => quasar.dark.isActive ),
            toggleDark: () => { quasar.dark.toggle(); },
            updateTime: updateTime,
        };
    },
    
    methods: {
        onScroll(this:ComponentPublicInstance, data:ScrollObserverData) {
/*            const page = this.$refs.page as ComponentPublicInstance;
            const position = data.position.top;

            let id = '';
            let dist = 0;
            const sections = page.$refs as Record<string, ComponentPublicInstance>;
            for(const key in sections) {
                const section = sections[key];
                if(!section) continue;

                const el = section.$el as HTMLDivElement|undefined;
                if(!el || !el.id) continue;

                const offsetTop = el.offsetTop;
                const offsetBot = offsetTop + el.offsetHeight;
                if(offsetTop <= position+10 && offsetBot > position && (!id || offsetTop < dist)) {
                    dist = offsetTop;
                    id = el.id;
                }
            }
            (this as any).currentTab = id;*/
        },
    },
});
