import { render } from "./layout.vue?vue&type=template&id=0d776419&scoped=true"
import script from "./layout.vue?vue&type=script&lang=ts"
export * from "./layout.vue?vue&type=script&lang=ts"

import "./layout.vue?vue&type=style&index=0&id=0d776419&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-0d776419"
/* hot reload */
if (module.hot) {
  script.__hmrId = "0d776419"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0d776419', script)) {
    api.reload('0d776419', script)
  }
  
  module.hot.accept("./layout.vue?vue&type=template&id=0d776419&scoped=true", () => {
    api.rerender('0d776419', render)
  })

}

script.__file = "html/vue/layout.vue"

export default script