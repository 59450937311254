import { render } from "./interest-page.vue?vue&type=template&id=944ff49a&scoped=true"
const script = {}

import "./interest-page.vue?vue&type=style&index=0&id=944ff49a&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-944ff49a"
/* hot reload */
if (module.hot) {
  script.__hmrId = "944ff49a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('944ff49a', script)) {
    api.reload('944ff49a', script)
  }
  
  module.hot.accept("./interest-page.vue?vue&type=template&id=944ff49a&scoped=true", () => {
    api.rerender('944ff49a', render)
  })

}

script.__file = "html/vue/interest-page.vue"

export default script