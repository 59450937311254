<style scoped>
    A.img-link {
        color: transparent;
    }
</style>

<template>
    <div class="q-px-md">
        <h3>Welcome to my Homepage</h3>
        <p>
            Back when I was in school and the Internet was new everyone needed their own homepage, and so did I.
            As the Net has grown various walled gardens have grown up that are very good at what they do, and
            the various attempts I made over the years to keep and maintain a homepage have turned into various
            colors of desert of half-configured wiki systems that are never updated past their creation.
        </p>
        <p>
            On this latest incarnation, I'm taking a different approach and instead of describing myself and what
            I've been working on lately, will try to direct you to various websites that are designed to do a
            much better job of that than I could.
        </p>
        <p>
            <a class="img-link" href="https://www.facebook.com/profile.php?id=1028454371" target="_blank">
                <q-icon class="q-pa-sm" color="primary" size="96px" name="fab fa-facebook" />
            </a>
            <a class="img-link" href="https://www.linkedin.com/in/handerson1/" target="_blank">
                <q-icon class="q-pa-sm" color="primary" size="96px" name="fab fa-linkedin" />
            </a>
            <a class="img-link" href="https://github.com/odysseus654" target="_blank">
                <q-icon class="q-pa-sm" color="primary" size="96px" name="fab fa-github" />
            </a>
        </p>
    </div>
</template>